import { Component, DestroyRef, effect, inject, Input, input, signal } from '@angular/core'
import { TableComponent } from "@app/shared/components/table";
import { RoamCardComponent } from "@app/shared/components/roam-layout/roam-card/roam-card.component";
import { ButtonModule } from "@app/shared/components/button/button.module";
import { PaginationListService } from "@app/shared/services/pagination-list.service";
import { TableConfigModel } from "@app/shared/components/table/model";
import { PageEvent } from '@angular/material/paginator';
import { MeetingService } from '@app/shared/services/meeting.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MeetingFormDialogComponent } from '@app/pages/meeting/+features';
import { filter, first } from 'rxjs';
import { RoamAclService } from '@app/core/access-control/+data-access';
import { Router } from '@angular/router';

@Component({
  selector: 'upcoming-meeting',
  standalone: true,
  imports: [TableComponent, RoamCardComponent, ButtonModule],
  providers: [PaginationListService],
  template: `
    <app-roam-card [title]="'Upcoming Meetings'"
                   [footer]="false">

      <div actRef class="roam-flex roam-items-center roam-gap-4">
        <button-manage icon="add" label="Schedule New" 
          (onClick)="openMeetingDialog()"
        />

        @if (acl.isBoardMember()) {
          <button-manage 
            icon="eye" 
            label="View All" 
            (onClick)="viewBoardMeetings()"
          />
        }
      </div>
      
      <div class="mat-table-dark dark">
        <app-table [httpPagination]="true"
                   [tableConfig]="tableConfig"
                   [emptyRow]="true"
                   [isLoading]="isLoading()"
                   (pageChange)="updateTable($event)"
        >
          <ng-template #customTemplate let-key="key" let-data="data">
            @if (key === 'action') {
              <div class="text-underline c-pointer fw-500">
                Detail
              </div>
            }
          </ng-template>
        </app-table>
      </div>
      
    </app-roam-card>
  `
})
export class UpcomingMeetingComponent{
  readonly acl = inject(RoamAclService);
  readonly router = inject(Router);

  @Input()
  totalMeetings: number = 0;

  id = input<string>();
  meetings = input<any[]>([]);
  tableConfig = new TableConfigModel();
  pagination = inject(PaginationListService);
  isLoading = signal<boolean>(false);
  #dialog = inject(MatDialog);
  #destroyRef = inject(DestroyRef);
  meetingService = inject(MeetingService);

  dataKeyMeeting: string[] = [
    'date',
    'attended',
    'notes',
    'action',
  ];

  dataLabelMeeting: string[] = [
    'Date',
    'Attended',
    'Note',
    'Action'
  ]

  initTable = effect(() => {
    this.tableConfig = this.pagination.setTableConfig(
      this.dataKeyMeeting,
      this.dataLabelMeeting,
      this.meetings()
    )

    this.tableConfig.dataType['date'] = 'M/d/yyyy';
    this.tableConfig.dataType['action'] = 'custom';
    this.tableConfig.dataTotal = this.totalMeetings || this.meetings().length;
  })

  viewBoardMeetings(): void {
    this.router.navigate(["board-portal", "meeting", "list"]);
  }

  updateTable(page: PageEvent): void {  
    this.isLoading.set(true);
    this.pagination.updatePageInfo(page);
    this.meetingService.getMeetings(this.id(),page.pageSize, page.pageIndex + 1 )
        .pipe(
          takeUntilDestroyed(this.#destroyRef)
        )
        .subscribe({
          next: resp => {
            this.tableConfig.dataSource = resp.data;
            this.isLoading.set(false);
          },
          error: () => {
            this.isLoading.set(false);
          }
        })
  }

  openMeetingDialog(): void {
    this.#dialog
      // TODO:
      .open(MeetingFormDialogComponent, {
        backdropClass: "roam-dialog-backdrop",
        panelClass: "roam-dialog-panel",
        width: "50rem",
        data: {},
      })
      .afterClosed()
      .pipe(first(), filter(Boolean))
      .subscribe(() => {
        
      });
  }

}
