import { dialogConfig } from '@app/core/const/dialog.const';
import { Component, effect, inject, input } from "@angular/core";
import { TableComponent } from "@app/shared/components/table";
import { TableConfigModel } from "@app/shared/components/table/model";
import { PaginationListService } from "@app/shared/services/pagination-list.service";
import { RoamCardComponent } from "@app/shared/components/roam-layout/roam-card/roam-card.component";
import { Router } from '@angular/router'
import { ButtonManageComponent } from "@app/shared/components/button/button-manage/button-manage.component";
import { MatDialog } from "@angular/material/dialog";
import { FinancialAddAccountDialogComponent } from "@app/pages/financials/dialogs/financial-add-account-dialog/financial-add-account-dialog.component";
import { RoamAclService } from '@app/core/access-control/+data-access';

@Component({
  selector: 'bank-account',
  standalone: true,
  imports: [TableComponent, RoamCardComponent, ButtonManageComponent],
  providers: [PaginationListService],
  template: `
    <app-roam-card [title]="'Bank Accounts'">
      @if (acl.isManager()) {
        <div actRef>
          <button-manage icon="add" label="Add New" 
            (onClick)="addNewAccount()"
        />
      </div>
      }

      <div class="mat-table-dark dark">
        <app-table [httpPagination]="true"
                   [tableConfig]="tableConfig"
                   [emptyRow]="true"
                   (pageChange)="pagination.updatePage($event, tableConfig)"
                   (selectData)="onSelectData($event)"
        >
          <ng-template #customTemplate let-key="key" let-data="data">
            @if (key === 'action') {
              <img
                      src="/assets/svg/edit-black.svg"
                      alt="pencil Icon"
                      class="c-pointer"
                      style="margin-right: 10px"
              />
              <img
                      src="/assets/svg/trash-red-icon.svg"
                      alt="Trash Icon"
                      class="c-pointer"
              />
            }
          </ng-template>
        </app-table>
      </div>
    </app-roam-card>
  `
})
export class BankAccountComponent {
  readonly acl = inject(RoamAclService);
  bankAccounts = input<any[]>([])
  tableConfig = new TableConfigModel();
  pagination = inject(PaginationListService);
  #dialog = inject(MatDialog);

  dataKeyAccount: string[] = [
    "name",
    "subtype",
    "number",
    "balance",
    "action",
  ];

  dataLabelRequest: string[] = [
    'Account',
    'Subtype',
    'Number',
    'Balance',
    'Action'
  ];

  constructor (
    private router: Router,
  ) {
  }

  initTable = effect(() => {
    this.tableConfig = this.pagination.setTableConfig(
      this.dataKeyAccount,
      this.dataLabelRequest,
      this.bankAccounts()
    )

    this.tableConfig.dataType['action'] = 'custom';
  });

  public onSelectData(data: any) {
    this.router.navigate(['/reporting/report-detail/account-register', data.id]); // Modify the route as per your application's route configuration
  }

  addNewAccount() {
    this.#dialog.open(FinancialAddAccountDialogComponent, {
      ...dialogConfig.formFill
    })
  }
}
